/*global jQuery*/
/* eslint-disable */
jQuery(function($) {
	function detectIE() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	}

	// Internet Explorer 6-11
	var isIE = /*@cc_on!@*/ false || !!document.documentMode;

	if (isIE) {
		var versionNum = detectIE();

		if (versionNum <= 11) {
			var header = $('#header');
			var browserUpgradeText =
				'<div class="browser-warning">' +
				'<button class="browser-warning--close">' +
				'X' +
				'</button>' +
				'<p>You are using an unsupported browser. Please switch to Chrome, Firefox or Edge.</p>';

			header.prepend(browserUpgradeText);
		}
	}

	$('.browser-warning--close').unbind('click');
	$('.browser-warning--close').click(function() {
		$('.browser-warning').hide();
		return false;
	});
});
/* eslint-enable */
